<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-meeting-room">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col sm:w-full md:w-full lg:block lg:w-1/2">
              <img src="@/assets/images/pages/meeting.png" alt="login" class="mx-auto" style="max-width: 400px" />
            </div>

            <div v-if="!invalidMeeting" class="vx-col flex justify-center items-center sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg" style="min-height: 400px">
              <div class="p-8 login-tabs-container">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4 text-large">{{ $t('vue.hi') }}</h4>
                  <p class="text-medium">{{ $t('vue.meetingWaitMessage') }}</p>
                </div>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <div v-if="false" class="pt-4">
                  <vs-input
                    @keyup.enter="login"
                    v-validate="'required|email|min:3'"
                    data-vv-validate-on="blur"
                    :name="$t('inputs.email')"
                    icon-no-border
                    icon="icon icon-mail"
                    icon-pack="feather"
                    :label-placeholder="$t('inputs.email')"
                    v-model="email"
                    class="w-full"
                  />
                  <span class="text-danger text-sm">{{ errors.first($t('inputs.email')) }}</span>

                  <vs-input
                    @keyup.enter="login"
                    data-vv-validate-on="blur"
                    v-validate="'required|min:3'"
                    :name="$t('inputs.name')"
                    icon-no-border
                    icon="icon icon-user"
                    icon-pack="feather"
                    :label-placeholder="$t('inputs.name')"
                    v-model="visitorName"
                    class="w-full mt-8"
                  />
                  <span class="text-danger text-sm">{{ errors.first($t('inputs.name')) }}</span>

                  <vs-button class="mt-8" :disabled="!validateForm" @click="joinMeeting">{{ $t('vue.joinMeeting') }}</vs-button>
                </div>
              </div>
            </div>
            <div v-if="invalidMeeting" class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">
                <div class="vx-card__title m-20">
                  <h4 class="mb-4">{{ $t('vue.invitationLinkExpired') }}</h4>
                  <p>{{ $t('vue.invitationLinkExpiredMessage') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import 'firebase/functions'
// eslint-disable-next-line
import firebase from 'firebase/app'
import { commonFunctions } from '@/mixins/commonFunctions'

export default {
  mixins: [commonFunctions],
  data() {
    return {
      email: '',
      visitorName: '',
      invalidMeeting: false,
      meetingId: null
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== ''
    }
  },
  async mounted() {
    const vm = this
    vm.meetingId = vm.$route.query.mid
    if (!vm.meetingId) {
      vm.invalidMeeting = true
      return
    }
    const checkMeeting = this.$functions.httpsCallable('checkMeeting')
    const res = await checkMeeting({ meetingId: vm.meetingId })

    if (res && res.data && res.data.id === vm.meetingId) {
      const ref = vm.$database.ref(`/meetings/${vm.meetingId}`)
      const isOfflineForDatabase = {
        visitor: 'offline',
        visitor_date: firebase.database.ServerValue.TIMESTAMP
      }
      const isOnlineForDatabase = {
        visitor: 'online',
        visitor_date: firebase.database.ServerValue.TIMESTAMP
      }
      vm.$database.ref('.info/connected').on('value', function (snapshot) {
        if (snapshot.val() === false) {
          return
        }
        ref
          .onDisconnect()
          .update(isOfflineForDatabase)
          .then(function () {
            ref.update(isOnlineForDatabase)
          })
      })

      /* SURFLY NO LONGER USED */
      // if (vm.meetingId) {
      //   const meetingRef = vm.$database.ref(`meetings/${vm.meetingId}`)

      //   meetingRef.on('value', async (snapshot) => {
      //     const data = snapshot.val()
      //     if (data && data.agent === 'online' && data.follower_link) {
      //       let url = data.follower_link
      //       if (vm.$route.query.email) {
      //         url = `${url}?email=${vm.$route.query.email}`
      //       }
      //       if (data.session_id) {
      //         const getSurflySessionInformation = this.$functions.httpsCallable('getSurflySessionInformation')
      //         const sessionInfo = await getSurflySessionInformation({
      //           session_id: data.session_id
      //         })
      //         this.coBrowsing = res.data
      //         /* Redirect only if Session has not ended */
      //         if (!sessionInfo.data.end_time) {
      //           setTimeout(() => {
      //             location.href = url
      //           }, 2000)
      //         }
      //       }
      //     }
      //   })
      // }
    } else {
      vm.invalidMeeting = true
    }
  },
  methods: {
    joinMeeting() {}
  }
}
</script>

<style lang="scss">
#page-meeting-room {
  .text-large {
    font-size: 1.7rem;
  }
  .text-medium {
    font-size: 1.4rem;
  }
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
</style>
