var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
      attrs: { id: "page-meeting-room" },
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col sm:w-full md:w-full lg:block lg:w-1/2",
                      },
                      [
                        _c("img", {
                          staticClass: "mx-auto",
                          staticStyle: { "max-width": "400px" },
                          attrs: {
                            src: require("@/assets/images/pages/meeting.png"),
                            alt: "login",
                          },
                        }),
                      ]
                    ),
                    !_vm.invalidMeeting
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vx-col flex justify-center items-center sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg",
                            staticStyle: { "min-height": "400px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "p-8 login-tabs-container" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "vx-card__title mb-4" },
                                  [
                                    _c(
                                      "h4",
                                      { staticClass: "mb-4 text-large" },
                                      [_vm._v(_vm._s(_vm.$t("vue.hi")))]
                                    ),
                                    _c("p", { staticClass: "text-medium" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("vue.meetingWaitMessage"))
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "vs-progress",
                                  {
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary",
                                    },
                                  },
                                  [_vm._v("primary")]
                                ),
                                false
                                  ? _c(
                                      "div",
                                      { staticClass: "pt-4" },
                                      [
                                        _c("vs-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required|email|min:3",
                                              expression:
                                                "'required|email|min:3'",
                                            },
                                          ],
                                          staticClass: "w-full",
                                          attrs: {
                                            "data-vv-validate-on": "blur",
                                            name: _vm.$t("inputs.email"),
                                            "icon-no-border": "",
                                            icon: "icon icon-mail",
                                            "icon-pack": "feather",
                                            "label-placeholder":
                                              _vm.$t("inputs.email"),
                                          },
                                          on: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.login.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.email,
                                            callback: function ($$v) {
                                              _vm.email = $$v
                                            },
                                            expression: "email",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-danger text-sm",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  _vm.$t("inputs.email")
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("vs-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required|min:3",
                                              expression: "'required|min:3'",
                                            },
                                          ],
                                          staticClass: "w-full mt-8",
                                          attrs: {
                                            "data-vv-validate-on": "blur",
                                            name: _vm.$t("inputs.name"),
                                            "icon-no-border": "",
                                            icon: "icon icon-user",
                                            "icon-pack": "feather",
                                            "label-placeholder":
                                              _vm.$t("inputs.name"),
                                          },
                                          on: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.login.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.visitorName,
                                            callback: function ($$v) {
                                              _vm.visitorName = $$v
                                            },
                                            expression: "visitorName",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-danger text-sm",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  _vm.$t("inputs.name")
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "mt-8",
                                            attrs: {
                                              disabled: !_vm.validateForm,
                                            },
                                            on: { click: _vm.joinMeeting },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("vue.joinMeeting"))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.invalidMeeting
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "p-8 login-tabs-container" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "vx-card__title m-20" },
                                  [
                                    _c("h4", { staticClass: "mb-4" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("vue.invitationLinkExpired")
                                        )
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "vue.invitationLinkExpiredMessage"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }